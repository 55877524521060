class WebTools {
  public static embededYoutubeURL(video: string): URL | null {
    // iframe have a src="" parameter, extract it
    var srcEqualsMatch = video.match(/src="([^ ]*)"/)
    var url = srcEqualsMatch
      ? new URL(srcEqualsMatch[1]) // the content of src=""
      : new URL(video)

    var videoId

    // Only valid URL gets built, ignore http, https, etc: we build it
    if (url) {
      if (url.hostname.endsWith('.youtube.com') || url.hostname === 'youtube.com') {
        // possible www.
        var pathname = url.pathname
        if (pathname.startsWith('/embed/')) {
          // https://www.youtube.com/embed/dcIHFTSDzXc
          videoId = pathname.substring(7) // skip slash embed slash
        } else if (pathname.startsWith('/shorts/')) {
          // https://www.youtube.com/shorts/…
          videoId = pathname.substring(8) // skip slash embed slash
        } else {
          // https://www.youtube.com/watch?v=dcIHFTSDzXc&feature=youtu.be  note: &... is optional
          videoId = url.searchParams.get('v') // look for the v= parameter
        }
      } else if (url.hostname === 'youtu.be') {
        // https://youtu.be/dcIHFTSDzXc
        videoId = url.pathname.substring(1) // anything after the slash
      }
    }
    return videoId === null || videoId === ''
      ? null // or nothing
      : new URL('https://www.youtube.com/embed/' + videoId) // we want this format
  }

  public static extractbaseURL(url: string): string {
    var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      var hostname = match[2].split('.')
      return hostname[0]
    }
    return ''
  }
}

export { WebTools }
