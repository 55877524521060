<template>
  <v-theme-provider light>
    <v-form ref="form" @submit.prevent="submit">
      <v-overlay :value="isPageLoading">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
      </v-overlay>
      <div v-if="isAcceptedProp && !isPageLoading" :timeout="infinityTimeoutProp">
        <BannerAccepted />
      </div>
      <div v-if="isRejectedProp && !isPageLoading" :timeout="infinityTimeoutProp">
        <BannerRejected />
      </div>
      <div v-if="!wasEulaAlreadyAcceptedProp && !showRedeemFormProp && !isPageLoading">
        <BannerVirgin />
      </div>
      <div v-if="isSubmittedProp && !isPageLoading">
        <BannerSubmitted />
      </div>
      <div justify="center" align="center" v-if="showRedeemFormProp && !isPageLoading">
        <ComponentRedeem @callRefreshProfileAndProperties="refreshProfileAndProperties" v-model="showRedeemFormProp" />
      </div>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12">
          <v-snackbar v-model="showGenericFailure" :timeout="infinityTimeoutProp">
            <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
              <span class="centered">
                <span>That's unexpected! {{ genericFailureErrorMessage }}</span>
                <v-spacer />
                <v-btn @click="showGenericFailure = false" text style="display: flex; justify-content: flex-end">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </span>
            </v-container>
          </v-snackbar>
        </v-col>
      </v-row>
      <base-section id="profile" class="text-center" space="86">
        <base-section-heading :title="applicationTitle" color="accent"
          ><v-icon left>mdi-web</v-icon>{{ networkname }}
          <v-row />
          <v-row>
            <v-col cols="12" sm="12">
              <v-avatar v-show="!formProfile.avatar" tile size="144">
                <v-icon v-show="!isPageLoading" size="120" color="grey">mdi-account-circle</v-icon>
              </v-avatar>
              <v-avatar v-show="formProfile.avatar && !isPageLoading" tile size="144">
                <v-img class="profile-image-style" :src="formProfile.avatar"></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </base-section-heading>
        <v-responsive class="d-flex align-center mx-auto mb-4" height="100%" max-width="800" width="100%">
          <v-container :loading="isPageLoading">
            <div class="panel">
              <v-card elevation="0" :loading="isPageLoading">
                <v-col class="col">
                  <template>
                    <v-card elevation="1">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field dense readonly v-model="formProfile.firstname" label="First name" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense readonly v-model="formProfile.lastname" label="Last name" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense readonly v-model="formProfile.profession" label="Profession" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense readonly v-model="formProfile.sales" color="primary" label="Yearly Sales" hint="Number of transactions in the past 12 months" min="1" max="365" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <v-spacer><br /></v-spacer>
                    <v-card elevation="2">
                      <v-card-title>Location</v-card-title>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field dense readonly v-model="formProfile.city" label="City" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense readonly v-model="formProfile.county" label="County" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense readonly v-model="formProfile.zip" label="Zip Code" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense readonly v-model="formProfile.statename" label="State" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <v-spacer><br /></v-spacer>
                    <v-card elevation="2">
                      <v-card-title>Contact</v-card-title>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field dense readonly v-model="formProfile.business" label="Company Name" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field dense v-if="formProfile.license" readonly v-model="formProfile.license" label="License Number" />
                          </v-col>
                        </v-row>
                        <v-card elevation="0" class="d-flex flex-wrap justify-space-between mb-4">
                          <v-btn v-ripple="false" x-large rounded class="mx-4 my-4 nohover-button" @click="composeMailAction()"><v-icon>mdi-email</v-icon><span>&nbsp;Email</span></v-btn>
                          <v-btn v-ripple="false" x-large rounded class="mx-4 my-4 nohover-button" @click="sendSMSAction()"><v-icon>mdi-message-outline</v-icon><span>&nbsp;Text</span></v-btn>
                          <v-btn v-ripple="false" x-large rounded class="mx-4 my-4 nohover-button" @click="makePhoneCallAction()"><v-icon>mdi-phone</v-icon><span>&nbsp;Call</span></v-btn>
                        </v-card>
                      </v-container>
                    </v-card>
                    <v-spacer><br /></v-spacer>
                    <v-card elevation="2" v-if="formProfile.summary">
                      <v-card-title>Summary</v-card-title>
                      <v-container fluid>
                        <v-col cols="12">
                          <v-textarea readonly v-model="formProfile.summary"> </v-textarea>
                        </v-col>
                      </v-container>
                    </v-card>
                    <v-spacer><br /></v-spacer>
                    <v-card elevation="1" v-if="formProfile?.websites?.length > 0">
                      <v-card-title>External Profiles</v-card-title>
                      <v-card-text class="d-flex flex-column mb-2">
                        <v-btn v-for="website in formProfile.websites" :key="website" :href="website" target="_blank" x-large color="primary" text class="my-2 text-capitalize text-h6 white nohover-button"> {{ webTools.extractbaseURL(website) }}</v-btn>
                      </v-card-text>
                    </v-card>
                    <v-spacer><br /></v-spacer>
                    <v-card elevation="2" v-if="formProfile.video">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <div class="video-max-width">
                              <iframe v-if="formProfile?.video?.length > 0" :src="embededYoutubeURL(formProfile.video)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </template>
                </v-col>
              </v-card>
            </div>
          </v-container>
        </v-responsive>
      </base-section>
    </v-form>
  </v-theme-provider>
</template>

<script>
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { RegistrationStage, AlertTimeout } from '@/RegistrationStage.ts'
import { WebTools } from '@/WebTools.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  components: {
    BannerAccepted: () => import('./BannerAccepted.vue'),
    BannerRejected: () => import('./BannerRejected.vue'),
    BannerSubmitted: () => import('./BannerSubmitted'),
    BannerVirgin: () => import('./BannerVirgin.vue')
  },

  props: ['titlePrefixProp', 'myselfProp'],

  data() {
    const defaultForm = Object.freeze({
      formProfile: {}
    })

    return {
      form: Object.assign({}, defaultForm),
      applicationTitle: '',
      person: {},
      defaultForm,
      formProfile: {},
      fullname: '',
      genericFailureErrorMessage: '',
      isPageLoading: true,
      networkname: '',
      registrationStage: RegistrationStage.Disabled, // https://stackoverflow.com/a/55379279
      showApproveDialog: false,
      showGenericFailure: false,
      showRejectDialog: false,
      showReviseDialog: false,
      showSavedProgress: false,
      showSavedSuccess: false,
      wasEulaAlreadyAccepted: false,
      websites: []
    }
  },

  mounted() {
    const paramsRoutes = this.$route.params
    this.person = paramsRoutes && paramsRoutes.person ? paramsRoutes.person : {}
    setTimeout(this.fetchProfile, this.nullTimeoutProp)
  },

  computed: {
    isNotSubmittedProp() {
      return this.registrationStage === RegistrationStage.NotSubmitted
    },

    isSubmittedProp() {
      return this.registrationStage === RegistrationStage.Submitted
    },

    isAcceptedProp() {
      return this.registrationStage === RegistrationStage.Accepted
    },

    isRejectedProp() {
      return this.registrationStage === RegistrationStage.Rejected
    },

    isRevisionProp() {
      return this.registrationStage === RegistrationStage.NotSubmitted
    },

    isCompletedProp() {
      return this.registrationStage === RegistrationStage.Completed
    },

    nullTimeoutProp() {
      return AlertTimeout.null
    },

    infinityTimeoutProp() {
      return AlertTimeout.infinity
    },

    showRedeemFormProp() {
      return this.registrationStage === RegistrationStage.Empty
    },

    wasEulaAlreadyAcceptedProp() {
      return this.myselfProp ? this.wasEulaAlreadyAccepted : true
    },

    webTools() {
      return WebTools
    }
  },

  methods: {
    composeMailAction() {
      const body = `Hello ${this.formProfile.firstname},`
      const subject = `Contact from your ${this.formProfile.networkname} network`
      document.location.href = `mailto:${this.formProfile.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    },

    makePhoneCallAction() {
      document.location.href = `tel:${this.formProfile.phone}`
    },

    sendSMSAction() {
      document.location.href = `sms:${this.formProfile.phone}`
    },

    embededYoutubeURL(video) {
      return WebTools.embededYoutubeURL(video)
    },

    fetchProfile() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '😎 ProfileReadOnlySection.fetchProfile', // log hint
        this.myselfProp ? endpoints.myprofileEndpoint : endpoints.userLookupEndpoint, // endpoint
        this.myselfProp ? environmentVariables.MY_PROFILE_KEY : environmentVariables.USER_LOOKUP_KEY, // code
        {
          id: this.person && this.person.id ? this.person.id : '00000000-0000-0000-0000-000000000000'
        }, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.genericFailureErrorMessage = ''
          this.isPageLoading = true
          this.showGenericFailure = false

          return true
        },

        // onSuccess
        (data) => {
          this.isPageLoading = false
          this.refreshProfileAndProperties(data.profile)
        },

        // All sort of errors
        (error, errorDescription) => {
          this.isPageLoading = false

          if (AuthServiceConstants.LOGGED_OUT !== error.message) {
            this.showGenericFailure = true
            this.genericFailureErrorMessage = errorDescription
          }
        }
      )
    },

    refreshProfileAndProperties(profile) {
      this.formProfile = profile
      if (this.formProfile && !this.formProfile.websites) {
        this.formProfile.websites = []
      }
      this.registrationStage = profile.registrationstage
      this.fullname = profile.fullname
      this.applicationTitle = `${this.titlePrefixProp} ${profile.fullname}`
      this.networkname = profile.networkname
      this.professioncode = profile.professioncode

      this.eulaaccepted = Boolean(profile.eulaaccepted)
      this.wasEulaAlreadyAccepted = this.eulaaccepted
      this.isPageLoading = false
    }
  }
}
</script>

<style scoped>
._next {
  position: relative;
  top: +24px;
  width: 100%;
}

.panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 16px 10px;
  position: relative;
  width: 100%;
}

.text-fields-row {
  display: flex;
}

.nohover-button::before {
  display: none;
}

.profile-image-style {
  border-radius: 20px;
}

.video-max-width {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer {
  margin: 0;
}

:deep() .v-input__slot::before {
  border-style: none !important;
}

:deep() .v-input__slot::after {
  border-style: none !important;
}

.centered {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
</style>
